.news-poster {
  margin-bottom: 30px;
  background: url(../img/news-poster.jpg) no-repeat center center;
  height: 500px;
  background-size: cover;
}
@media (max-width: 767px) {
  .news-poster {
    background: url(../img/news-poster-xs.jpg) no-repeat center center;
    height: 150px;
    background-size: 100%;
  }
}

.news-list__header {
  margin-bottom: 30px;
  font-size: 1.875em;
  font-weight: 500;
  color: #eb2837;
}

.news-list__archive {
  margin-bottom: 30px;
  margin-top: 30px;
  font-size: 1.5em;
  font-weight: normal;
  color: #000;
}

.news-list__item {
  .media__object {
    width: 120px;
    height: 80px;
    background: url('../img/news-list-placeholder.png') no-repeat center center;
    background-size: contain;
  }
  .col-md-10.col-lg-10 {
    margin-left: 5%;
    .media__body {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}

.news-list__date {
  font-size: 0.875em;
  color: #414141;
}

.news-list__title {
  font-size: 1.125em;

  a {
    color: #eb2837;
  }
}

.news-list__meta {
  font-size: 0.875em;
  color: #353535;
}

@media (max-width:767px) {
  .news-list__item {
    .col-md-10.col-lg-10 {
      margin-left: 0;
      .media__body {
        padding-left: 0;
        padding-right: 0;
        padding-top: 20px;
        margin-left: 0;
      }
    }
  } 
}

@media (min-width:768px) and (max-width:991px) {
  .news-list__item {
    .col-md-10.col-lg-10 {
    margin-left: 0;
      .media__body {
        padding-left: 0;
        padding-right: 0;
        padding-top: 20px;
      }
    }
  } 
}
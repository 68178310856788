//
// Page, 基础页面
// --------------------------------------------------
html,
body {
  height: 100%;
}

html {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
  font-family: $font-family-roboto;
  font-size: $font-size-base;
  line-height: $line-height-base;
  color: $gray-dark;
  background-color: $body-bg;
}

a {
  color: $link-color;
  text-decoration: none;

  &:hover,
  &:focus {
    color: $link-hover-color;
    text-decoration: $link-hover-decoration;
  }

  &:focus {
    @include tab-focus;
  }
}

// Images, 图片
// -------------------------
img {
  vertical-align: middle;
}

// 图片尺寸根据宽高比进行缩放，最大宽度为图片本身宽度，即：放大操作只能伸展到图片本
// 身大小，包裹区域空间再宽，图片不再进行伸展，而是居中显示
.img-responsive {
  @include img-responsive;
}

// 图片尺寸填充整个包裹区域，图片会拉伸到包裹区域
.img-fit {
  @include img-fit;
}

.img-responsive,
.img-fit {
  // background: url("/img/placeholder.png") no-repeat center center #f5f5f5;
}

// Rounded corners
.img-rounded {
  border-radius: $border-radius-large;
}

// Perfect circle
.img-circle {
  border-radius: 50%; // set radius in percents
}

// Typography, 内容排版
// -------------------------
.text-primary {
  color: $brand-primary !important;
}

.text-gray1 {
  color: $gray-darker !important;
}

.text-gray2 {
  color: $gray-dark !important;
}

.text-ellipsis {
  @include text-ellipsis;
}

.text-hide {
  @include text-hide;
}

.text_center {
  text-align: center;
}

// 间隔
// -------------------------
// Horizontal rules
hr {
  margin-top: $line-height-computed;
  margin-bottom: $line-height-computed;
  border: 0;
  border-top: 1px solid $hr-color;
}

.hr {
  display: block;
  clear: both;
  width: 100%;
  height: 20px;
  font: 0/0 a;
  background-color: lighten($gray-base, 92%);
}

// 字符间隔
.spacer {
  padding-right: 2px;
  padding-left: 2px;
  font-family: $font-family-simsun;
  font-size: 0.75em;
  color: lighten($gray-base, 80%);
  cursor: default;
}

// Floats
// -------------------------
.clearfix {
  @include clearfix-ie;
}

.center-block {
  @include center-block;
}

.pull-left {
  float: left !important;
}

.pull-right {
  float: right !important;
}

// Toggling content
.hidden,
.hide {
  display: none !important;
}

.show {
  display: block !important;
}

// Page, 页面级
// -------------------------

.page {
  position: relative;
  height: auto !important;
  height: 100%;
  min-height: 100%;
  overflow: hidden;
}

// 对左侧、顶部定位布局有影响
.page__container {
  position: relative;
}
.section__header-shape {
  position: relative;
  margin-top: -3.125em;
  margin-bottom: 3.125em;

  .title {
    position: relative;
    z-index: 2;
    // height: 80px;
    font-size: 2.1875em;
    font-weight: 300;
    text-align: center;
    // line-height: 80px;
    color: #fff;
    padding: 0.625em 0;
  }

  .shape {
    height: 0;
    display: block;
    position: absolute;
    z-index: 1;
    right: 0;
    left: 0;
    border-right: 45px solid transparent;
    border-bottom-style: solid;
    border-bottom-width: 90px;
    border-left: 45px solid transparent;
  }
}

.section__style {
  border-top-width: 2px;
  border-top-style: solid;
}

.section__style_1 {
  border-top-color: #eb2836;
}

.section__style_1 {
  .section__header-shape {
    .shape {
      border-bottom-color: #eb2836;
    }
  }
}

.section__style_2 {
  border-top-color: #2d302f;
}

.section__style_2 {
  .section__header-shape {
    .shape {
      border-bottom-color: #2d302f;
    }
  }
}

.section__style_2 {
  div.module:nth-of-type(1) {
    padding-bottom: 40px;
  }
}

.section__style_3 {
  border-top-color: #3a85ca;
}

.section__style_3 {
  .section__header-shape {
    .shape {
      border-bottom-color: #3a85ca;
    }
  }
}

.section__body {
  padding: 50px 0;
}

.section__body p {
  padding-bottom: 30px;
  font-size: $font-size-base;
  font-weight: 300;
  line-height: 1.56;
  text-align: left;
  color: #1c1c1c;
}

.intelligence {
  p:nth-of-type(odd) {
    line-height: 1.75;
    font-weight: 300;
    color: #666666;
  }

  p:nth-of-type(even) {
    line-height: 1.75;
    font-weight: bold;
    color: #666666;
  }
}

.descrip__title {
  padding-bottom: 30px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 1.875em;
  font-weight: 500;
  text-align: center;
  color: #e92836;
}

.question__btn {
  padding: 23px 78px;
  border-radius: 30px;
  font-size: 17px;
  line-height: 3.15;
  text-align: center;
  background-color: #2d302f;

  a {
    color: #fff;
  }
}

.solution__title-shap {
  position: relative;
  text-align: center;

  .section-solution__title {
    position: relative;
    z-index: 2;
    top: 12px;
    margin: 0 auto;
    margin-bottom: 30px;
    padding: 2px 0;
    border-radius: 30px;
    width: 300px;
    height: 35px;
    font-size: $font-size-base;
    font-weight: bold;
    line-height: 35px;
    text-align: center;
    color: #000000;
  }

  .shape__line {
    position: absolute;
    z-index: 1;
    margin: 0 auto;
    margin-top: 30px;
    height: 2px;
    width: 100%;
    background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0), #3a85ca, rgba(255, 255, 255, 0));
    background: linear-gradient(to right, rgba(255, 255, 255, 0), #3a85ca, rgba(255, 255, 255, 0));
  }
}

.section-solution__title,
.section-solution__contain {
  padding: 10%;
  height: 18.75em;
  border-style: solid;
  border-width: 1px;
  box-shadow: 1.5px 2.6px 7px 0 rgba(0, 0, 0, 0.1);
}

.section-solution__title_style1 {

  .section-solution__title,
  .section-solution__contain {
    border-color: #91b2d1;
    background-color: #b7d2ec;
  }

  .gutter_small {
    margin-bottom: 1em;
  }
}

.section-solution__title_style2 {

  .section-solution__title,
  .section-solution__contain {
    border-color: #bcc6d5;
    background-color: #e4e9f1;
  }
}

.section-solution__title_style3 {

  .section-solution__title,
  .section-solution__contain {
    border-color: #d7cfa5;
    background-color: #e7e5db;
  }
}

.section-solution__title_style4 {

  .section-solution__title,
  .section-solution__contain {
    border-color: #bcbcb9;
    background-color: #dcdcdb;
  }
}

.section-solution__contain dt {
  margin-bottom: 20px;
  font-size: $font-size-medium;
  font-weight: bold;
  line-height: 1.67;
  color: #0d4375;
}

.section-solution__contain dd {
  font-size: $font-size-normal;
  font-weight: 500;
  line-height: 1.71;
  color: #0d4375;
}

.wrapper-top {
  margin-top: 50px;
}

.index-poster {
  position: relative;
  width: 100%;
  height: 890px;
  overflow: hidden;
  // max-width: 1920px;
  // min-width: 1000px;
  margin-right: auto;
  margin-left: auto;

  .index-poster__container {
    width: 100%;
  }

  .index-poster__pagination {
    position: absolute;
    bottom: 80px;
    left: 5%;
    z-index: 9;
    // width: 100%;
    // text-align: center;
  }

  .swiper-pagination-switch {
    display: inline-block;
    width: 20px;
    height: 20px;
    border: solid 3px #00a1f1;
    border-radius: 50%;
    background: transparent;
    margin: 0 8px;
    cursor: pointer;
    transition: background-color .2s linear;

    &:focus {
      outline: none;
    }
  }

  .swiper-active-switch {
    border-color: #eb2836;
    background-color: #eb2836;
    outline: none;
  }
}

.index-poster__slide {
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;

  .text {
    position: absolute;
    top: 32%;
    left: 5%;

    span {
      display: block;
      line-height: 1;
      margin-bottom: 15px;
    }

    .text-gray2 {
      font-size: 1.5em;
    }

    .text-gray1,
    .text-primary {
      font-size: 2.875em;
    }
  }
}

.index-poster__slide1 {
  background-image: url('../img/index-poster-1.jpg');
}

.index-poster__slide2 {
  background-image: url('../img/index-poster-2.jpg');
}

.index-poster__slide3 {
  background-image: url('../img/index-poster-3.jpg');
}

@media (max-width: 999px) {
  .index-poster {
    height: 620px;
    .index-poster__pagination {
      bottom: 50px;
    }
  }

  .index-poster__slide1 {
    background-image: url('../img/index-poster-1-md.jpg');
  }

  .index-poster__slide2 {
    background-image: url('../img/index-poster-2-md.jpg');
  }

  .index-poster__slide3 {
    background-image: url('../img/index-poster-3-md.jpg');
  }
}

// section title font
@media (max-width: 767px) {
  .section__header-shape {
    .title {
      font-size: 1.125em;
    }
  }

  .index-section_1 {
    .title {
      padding: 1.8em 0;
    }
  }

  .index-section_2 {
    .title {
      padding: 1em 0;
    }
  }

  .index-section_3,
  .index-section_4 {
    .title {
      padding: 0.2em 0;
    }
  }

  .index-poster__slide {
    .text {
      .text-gray2 {
        font-size: 0.75em;
      }

      .text-gray1,
      .text-primary {
        font-size: 1.25em;
      }
    }
  }

  .index-poster {
    .swiper-pagination-switch {
      width: 10px;
      height: 10px;
      border: solid 1px #00a1f1;
    }
  }
}

@media (min-width: 768px) and (max-width:991px) {
  .section__header-shape {
    .title {
      font-size: 1.5em;
    }
  }
}


@media (min-width: 992px) and (max-width: 1191px) {
  .section__header-shape {
    .title {
      font-size: 1.75em;
    }
  }
}
//
// Page Header, 基础页面
// --------------------------------------------------

// 此处设置了高度，需要对 gh-header.js 中的 pinHeight 进行调整
$_page-header-heigth: 88px;
$_page-header-pin-heigth: 88px;

.page__header {
  width: 100%;
  background-color: rgb(255, 255, 255);
  box-shadow: 1px 1px 5px rgba(0, 0, 0, .1);
}

.page__header_pin {
  position: fixed;
  z-index: $zindex-sticky;
  top: 0;
  right: 0;
  left: 0;
  background-color: rgba(255, 255, 255, .8);
  box-shadow: 1px 1px 1px rgba(126, 126, 126, .1);
  animation: .8s linear headerPin;

  // .header__logo {
  //   height: $_page-header-pin-heigth - 10;
  //   margin-top: ($_page-header-pin-heigth - 10)/-2;
  // }

  // .nav__item {
  //   a {
  //     height: $_page-header-pin-heigth - 4;
  //     line-height: $_page-header-pin-heigth - 4;
  //   }
  // }

  // .header__sign {
  //   margin-top: $_page-header-pin-heigth/2 - 30/2 - 4;
  // }
}

@keyframes headerPin {
  0% {
    transform: translateY(-100%);
  }

  100% {
    transform: translateY(0);
  }
}


.header__section {
  position: relative;
  padding: 0 5%;
}

// logo
.header__logo {
  position: absolute;
  top: 50%;
  left: 5%;
  height: 80px;
  width: auto;
  margin-top: -40px;

  img {
    height: auto;
    max-height: 100%;
  }
}

// navigation
.nav {
  float: right;

  li {
    float: left;
    list-style: none;

    a {
      display: block;

      &:hover,
      &:focus {
        text-decoration: none;
      }

    }
  }
}

.nav__item {
  a {

    padding: 0 25px;
    height: $_page-header-heigth - 4;
    line-height: $_page-header-heigth;
    border-bottom: 4px solid transparent;
    color: #2b2b2b;

    &:hover {
      border-bottom-color: $brand-primary;
      color: $brand-primary;
    }

    &:focus {
      border-bottom-color: transparent;
      color: #2b2b2b;
    }
  }
}

.nav__item_active {
  a {

    &,
    &:hover,
    &:focus {
      color: $brand-primary;
      border-bottom-color: $brand-primary;
    }
  }
}

.header__sign {
  height: 30px;
  margin-top: $_page-header-heigth/2 - 30/2 - 4;
  margin-left: 30px;
  padding: 0 .5em;
  line-height: 30px;
  background-color: $brand-primary;
  color: #fff;
  border-radius: 15px;

  &:hover,
  &:focus {
    color: #fff;
    background-color: darken($brand-primary, 5%);
  }
}

@media (max-width:767px) {
  .header__logo {
    top: 40px;
    height: 50px;
  }
  .navbar-collapse {
    margin-bottom: 15px;
  }
  .nav {
    float: none;
    li {
      float: none;
      text-align: center;
      a {
        padding: 10px 15px;
        height: auto;
        line-height: normal;
      }
      .header__sign {
        width: 30%;
        margin-left: 30%;
        border-radius: 40px;
      }
    }
    .nav__item_active {
      a {
          border-bottom-color: transparent;
      }
    }
  }
}

@media (min-width:768px) and (max-width:991px) {
  .nav {
    font-size: 0.75em;
    .nav__item {
      a {
        padding: 0 10px;
      }
    }
  }
}
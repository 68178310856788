/* yui3 */
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
legend,
input,
textarea,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0;
}

address,
caption,
cite,
code,
dfn,
em,
th,
var {
  font-style: normal;
  font-weight: normal;
}

ol,
ul {
  list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
  font-weight: normal;
}

i {
  font-style: normal;
}

caption,
th {
  text-align: left;
}

/* normalize.css v7.0.0 */
/* Document ========================================================================== */
/**
 * 1. Correct the line height in all browsers.
 * 2. Prevent adjustments of font size after orientation changes in
 *    IE on Windows Phone and in iOS.
 */
html {
  line-height: 1.15;
  /* 1 */
  -ms-text-size-adjust: 100%;
  /* 2 */
  -webkit-text-size-adjust: 100%;
  /* 2 */
}

/* Sections ========================================================================== */
/**
 * Add the correct display in IE 9-.
 */
article,
aside,
footer,
header,
nav,
section {
  display: block;
}

/* Grouping content ========================================================================== */
/**
 * Add the correct display in IE 9-.
 * 1. Add the correct display in IE.
 */
figcaption,
figure,
main {
  /* 1 */
  display: block;
}

/**
 * Add the correct margin in IE 8.
 */
figure {
  margin: 1em 40px;
}

/**
 * 1. Add the correct box sizing in Firefox.
 * 2. Show the overflow in Edge and IE.
 */
hr {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

/* Text-level semantics ========================================================================== */
/**
 * 1. Remove the gray background on active links in IE 10.
 * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
 */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */
}

/**
 * 1. Remove the bottom border in Chrome 57- and Firefox 39-.
 * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
 */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  /* 2 */
}

/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */
b,
strong {
  font-weight: bolder;
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

/**
 * Add the correct font style in Android 4.3-.
 */
dfn {
  font-style: italic;
}

/**
 * Add the correct background and color in IE 9-.
 */
mark {
  background-color: #ff0;
  color: #000;
}

/**
 * Add the correct font size in all browsers.
 */
small {
  font-size: 80%;
}

/**
 * Prevent `sub` and `sup` elements from affecting the line height in
 * all browsers.
 */
sub,
sup {
  position: relative;
  vertical-align: baseline;
  line-height: 0;
  font-size: 75%;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

/* Embedded content ========================================================================== */
/**
 * Add the correct display in IE 9-.
 */
audio,
video {
  display: inline-block;
  /* normalize.css v1.1.3 */
  *display: inline;
  *zoom: 1;
}

/**
 * Add the correct display in iOS 4-7.
 */
audio:not([controls]) {
  display: none;
  height: 0;
}

/**
 * Remove the border on images inside links in IE 10-.
 */
img {
  border-style: none;
  /* normalize.css v1.1.3 */
  -ms-interpolation-mode: bicubic;
  /* 2 */
}

/**
 * Hide the overflow in IE.
 */
svg:not(:root) {
  overflow: hidden;
}

/* Forms ========================================================================== */
/**
 * 1. Change the font styles in all browsers (opinionated).
 * 2. Remove the margin in Firefox and Safari.
 */
button,
input,
optgroup,
select,
textarea {
  font-family: \5fae\8f6f\96c5\9ed1, "Microsoft YaHei", "Helvetica Neue", Helvetica, STHeiTi, sans-serif;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */
}

/**
 * Show the overflow in IE.
 * 1. Show the overflow in Edge.
 */
button,
input {
  /* 1 */
  overflow: visible;
}

/**
 * Remove the inheritance of text transform in Edge, Firefox, and IE.
 * 1. Remove the inheritance of text transform in Firefox.
 */
button,
select {
  /* 1 */
  text-transform: none;
}

/**
 * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
 *    controls in Android 4.
 * 2. Correct the inability to style clickable types in iOS and Safari.
 */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */
}

/**
 * Remove the inner border and padding in Firefox.
 */
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

/**
 * Restore the focus styles unset by the previous rule.
 */
button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 0 none transparent;
}

/**
 * Correct the padding in Firefox.
 */
fieldset {
  padding: 0.35em 0.75em 0.625em;
}

/**
 * 1. Correct the text wrapping in Edge and IE.
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 * 3. Remove the padding so developers are not caught out when they zero out
 *    `fieldset` elements in all browsers.
 */
legend {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  /* 1 */
  color: inherit;
  /* 2 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  white-space: normal;
  /* 1 */
}

/**
 * 1. Add the correct display in IE 9-.
 * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
 */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */
}

/**
 * Remove the default vertical scrollbar in IE.
 */
textarea {
  overflow: auto;
}

/**
 * 1. Add the correct box sizing in IE 10-.
 * 2. Remove the padding in IE 10-.
 */
[type="checkbox"],
[type="radio"] {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */
}

/**
 * Correct the cursor style of increment and decrement buttons in Chrome.
 */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

/**
 * 1. Correct the odd appearance in Chrome and Safari.
 * 2. Correct the outline style in Safari.
 */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
}

/**
 * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
 */
[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
 * 1. Correct the inability to style clickable types in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */
}

[role="button"] {
  cursor: pointer;
}

/* Interactive ========================================================================== */
/*
 * Add the correct display in IE 9-.
 * 1. Add the correct display in Edge, IE, and Firefox.
 */
details,
menu {
  display: block;
}

/*
 * Add the correct display in all browsers.
 */
summary {
  display: list-item;
}

/* Scripting ========================================================================== */
/**
 * Add the correct display in IE 9-.
 */
canvas {
  display: inline-block;
}

/**
 * Add the correct display in IE.
 */
template {
  display: none;
}

/* Hidden ========================================================================== */
/**
 * Add the correct display in IE 10-.
 */
[hidden] {
  display: none;
}

[role="button"] {
  cursor: pointer;
}

.gutter {
  padding-left: 35px;
  padding-right: 35px;
}

.gutter_small {
  padding-left: 8.75px;
  padding-right: 8.75px;
}

.container {
  margin-right: auto;
  margin-left: auto;
  padding-left: 35px;
  padding-right: 35px;
}

.container:before, .container:after {
  content: " ";
  display: table;
}

.container:after {
  clear: both;
}

@media (min-width: 768px) {
  .container {
    width: 790px;
  }
}

@media (min-width: 992px) {
  .container {
    width: 1010px;
  }
}

@media (min-width: 1200px) {
  .container {
    width: 1210px;
  }
}

.container-fluid {
  margin-right: auto;
  margin-left: auto;
  padding-left: 35px;
  padding-right: 35px;
}

.container-fluid:before, .container-fluid:after {
  content: " ";
  display: table;
}

.container-fluid:after {
  clear: both;
}

.row:before, .row:after {
  content: " ";
  display: table;
}

.row:after {
  clear: both;
}

.col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
  position: relative;
  min-height: 1px;
}

.col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12 {
  float: left;
}

.col-xs-1 {
  width: 8.33333%;
}

.col-xs-2 {
  width: 16.66667%;
}

.col-xs-3 {
  width: 25%;
}

.col-xs-4 {
  width: 33.33333%;
}

.col-xs-5 {
  width: 41.66667%;
}

.col-xs-6 {
  width: 50%;
}

.col-xs-7 {
  width: 58.33333%;
}

.col-xs-8 {
  width: 66.66667%;
}

.col-xs-9 {
  width: 75%;
}

.col-xs-10 {
  width: 83.33333%;
}

.col-xs-11 {
  width: 91.66667%;
}

.col-xs-12 {
  width: 100%;
}

.col-xs-pull-0 {
  right: auto;
}

.col-xs-pull-1 {
  right: 8.33333%;
}

.col-xs-pull-2 {
  right: 16.66667%;
}

.col-xs-pull-3 {
  right: 25%;
}

.col-xs-pull-4 {
  right: 33.33333%;
}

.col-xs-pull-5 {
  right: 41.66667%;
}

.col-xs-pull-6 {
  right: 50%;
}

.col-xs-pull-7 {
  right: 58.33333%;
}

.col-xs-pull-8 {
  right: 66.66667%;
}

.col-xs-pull-9 {
  right: 75%;
}

.col-xs-pull-10 {
  right: 83.33333%;
}

.col-xs-pull-11 {
  right: 91.66667%;
}

.col-xs-pull-12 {
  right: 100%;
}

.col-xs-push-0 {
  left: auto;
}

.col-xs-push-1 {
  left: 8.33333%;
}

.col-xs-push-2 {
  left: 16.66667%;
}

.col-xs-push-3 {
  left: 25%;
}

.col-xs-push-4 {
  left: 33.33333%;
}

.col-xs-push-5 {
  left: 41.66667%;
}

.col-xs-push-6 {
  left: 50%;
}

.col-xs-push-7 {
  left: 58.33333%;
}

.col-xs-push-8 {
  left: 66.66667%;
}

.col-xs-push-9 {
  left: 75%;
}

.col-xs-push-10 {
  left: 83.33333%;
}

.col-xs-push-11 {
  left: 91.66667%;
}

.col-xs-push-12 {
  left: 100%;
}

.col-xs-offset-0 {
  margin-left: 0%;
}

.col-xs-offset-1 {
  margin-left: 8.33333%;
}

.col-xs-offset-2 {
  margin-left: 16.66667%;
}

.col-xs-offset-3 {
  margin-left: 25%;
}

.col-xs-offset-4 {
  margin-left: 33.33333%;
}

.col-xs-offset-5 {
  margin-left: 41.66667%;
}

.col-xs-offset-6 {
  margin-left: 50%;
}

.col-xs-offset-7 {
  margin-left: 58.33333%;
}

.col-xs-offset-8 {
  margin-left: 66.66667%;
}

.col-xs-offset-9 {
  margin-left: 75%;
}

.col-xs-offset-10 {
  margin-left: 83.33333%;
}

.col-xs-offset-11 {
  margin-left: 91.66667%;
}

.col-xs-offset-12 {
  margin-left: 100%;
}

@media (min-width: 768px) {
  .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12 {
    float: left;
  }
  .col-sm-1 {
    width: 8.33333%;
  }
  .col-sm-2 {
    width: 16.66667%;
  }
  .col-sm-3 {
    width: 25%;
  }
  .col-sm-4 {
    width: 33.33333%;
  }
  .col-sm-5 {
    width: 41.66667%;
  }
  .col-sm-6 {
    width: 50%;
  }
  .col-sm-7 {
    width: 58.33333%;
  }
  .col-sm-8 {
    width: 66.66667%;
  }
  .col-sm-9 {
    width: 75%;
  }
  .col-sm-10 {
    width: 83.33333%;
  }
  .col-sm-11 {
    width: 91.66667%;
  }
  .col-sm-12 {
    width: 100%;
  }
  .col-sm-pull-0 {
    right: auto;
  }
  .col-sm-pull-1 {
    right: 8.33333%;
  }
  .col-sm-pull-2 {
    right: 16.66667%;
  }
  .col-sm-pull-3 {
    right: 25%;
  }
  .col-sm-pull-4 {
    right: 33.33333%;
  }
  .col-sm-pull-5 {
    right: 41.66667%;
  }
  .col-sm-pull-6 {
    right: 50%;
  }
  .col-sm-pull-7 {
    right: 58.33333%;
  }
  .col-sm-pull-8 {
    right: 66.66667%;
  }
  .col-sm-pull-9 {
    right: 75%;
  }
  .col-sm-pull-10 {
    right: 83.33333%;
  }
  .col-sm-pull-11 {
    right: 91.66667%;
  }
  .col-sm-pull-12 {
    right: 100%;
  }
  .col-sm-push-0 {
    left: auto;
  }
  .col-sm-push-1 {
    left: 8.33333%;
  }
  .col-sm-push-2 {
    left: 16.66667%;
  }
  .col-sm-push-3 {
    left: 25%;
  }
  .col-sm-push-4 {
    left: 33.33333%;
  }
  .col-sm-push-5 {
    left: 41.66667%;
  }
  .col-sm-push-6 {
    left: 50%;
  }
  .col-sm-push-7 {
    left: 58.33333%;
  }
  .col-sm-push-8 {
    left: 66.66667%;
  }
  .col-sm-push-9 {
    left: 75%;
  }
  .col-sm-push-10 {
    left: 83.33333%;
  }
  .col-sm-push-11 {
    left: 91.66667%;
  }
  .col-sm-push-12 {
    left: 100%;
  }
  .col-sm-offset-0 {
    margin-left: 0%;
  }
  .col-sm-offset-1 {
    margin-left: 8.33333%;
  }
  .col-sm-offset-2 {
    margin-left: 16.66667%;
  }
  .col-sm-offset-3 {
    margin-left: 25%;
  }
  .col-sm-offset-4 {
    margin-left: 33.33333%;
  }
  .col-sm-offset-5 {
    margin-left: 41.66667%;
  }
  .col-sm-offset-6 {
    margin-left: 50%;
  }
  .col-sm-offset-7 {
    margin-left: 58.33333%;
  }
  .col-sm-offset-8 {
    margin-left: 66.66667%;
  }
  .col-sm-offset-9 {
    margin-left: 75%;
  }
  .col-sm-offset-10 {
    margin-left: 83.33333%;
  }
  .col-sm-offset-11 {
    margin-left: 91.66667%;
  }
  .col-sm-offset-12 {
    margin-left: 100%;
  }
}

@media (min-width: 992px) {
  .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12 {
    float: left;
  }
  .col-md-1 {
    width: 8.33333%;
  }
  .col-md-2 {
    width: 16.66667%;
  }
  .col-md-3 {
    width: 25%;
  }
  .col-md-4 {
    width: 33.33333%;
  }
  .col-md-5 {
    width: 41.66667%;
  }
  .col-md-6 {
    width: 50%;
  }
  .col-md-7 {
    width: 58.33333%;
  }
  .col-md-8 {
    width: 66.66667%;
  }
  .col-md-9 {
    width: 75%;
  }
  .col-md-10 {
    width: 83.33333%;
  }
  .col-md-11 {
    width: 91.66667%;
  }
  .col-md-12 {
    width: 100%;
  }
  .col-md-pull-0 {
    right: auto;
  }
  .col-md-pull-1 {
    right: 8.33333%;
  }
  .col-md-pull-2 {
    right: 16.66667%;
  }
  .col-md-pull-3 {
    right: 25%;
  }
  .col-md-pull-4 {
    right: 33.33333%;
  }
  .col-md-pull-5 {
    right: 41.66667%;
  }
  .col-md-pull-6 {
    right: 50%;
  }
  .col-md-pull-7 {
    right: 58.33333%;
  }
  .col-md-pull-8 {
    right: 66.66667%;
  }
  .col-md-pull-9 {
    right: 75%;
  }
  .col-md-pull-10 {
    right: 83.33333%;
  }
  .col-md-pull-11 {
    right: 91.66667%;
  }
  .col-md-pull-12 {
    right: 100%;
  }
  .col-md-push-0 {
    left: auto;
  }
  .col-md-push-1 {
    left: 8.33333%;
  }
  .col-md-push-2 {
    left: 16.66667%;
  }
  .col-md-push-3 {
    left: 25%;
  }
  .col-md-push-4 {
    left: 33.33333%;
  }
  .col-md-push-5 {
    left: 41.66667%;
  }
  .col-md-push-6 {
    left: 50%;
  }
  .col-md-push-7 {
    left: 58.33333%;
  }
  .col-md-push-8 {
    left: 66.66667%;
  }
  .col-md-push-9 {
    left: 75%;
  }
  .col-md-push-10 {
    left: 83.33333%;
  }
  .col-md-push-11 {
    left: 91.66667%;
  }
  .col-md-push-12 {
    left: 100%;
  }
  .col-md-offset-0 {
    margin-left: 0%;
  }
  .col-md-offset-1 {
    margin-left: 8.33333%;
  }
  .col-md-offset-2 {
    margin-left: 16.66667%;
  }
  .col-md-offset-3 {
    margin-left: 25%;
  }
  .col-md-offset-4 {
    margin-left: 33.33333%;
  }
  .col-md-offset-5 {
    margin-left: 41.66667%;
  }
  .col-md-offset-6 {
    margin-left: 50%;
  }
  .col-md-offset-7 {
    margin-left: 58.33333%;
  }
  .col-md-offset-8 {
    margin-left: 66.66667%;
  }
  .col-md-offset-9 {
    margin-left: 75%;
  }
  .col-md-offset-10 {
    margin-left: 83.33333%;
  }
  .col-md-offset-11 {
    margin-left: 91.66667%;
  }
  .col-md-offset-12 {
    margin-left: 100%;
  }
}

@media (min-width: 1200px) {
  .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12 {
    float: left;
  }
  .col-lg-1 {
    width: 8.33333%;
  }
  .col-lg-2 {
    width: 16.66667%;
  }
  .col-lg-3 {
    width: 25%;
  }
  .col-lg-4 {
    width: 33.33333%;
  }
  .col-lg-5 {
    width: 41.66667%;
  }
  .col-lg-6 {
    width: 50%;
  }
  .col-lg-7 {
    width: 58.33333%;
  }
  .col-lg-8 {
    width: 66.66667%;
  }
  .col-lg-9 {
    width: 75%;
  }
  .col-lg-10 {
    width: 83.33333%;
  }
  .col-lg-11 {
    width: 91.66667%;
  }
  .col-lg-12 {
    width: 100%;
  }
  .col-lg-pull-0 {
    right: auto;
  }
  .col-lg-pull-1 {
    right: 8.33333%;
  }
  .col-lg-pull-2 {
    right: 16.66667%;
  }
  .col-lg-pull-3 {
    right: 25%;
  }
  .col-lg-pull-4 {
    right: 33.33333%;
  }
  .col-lg-pull-5 {
    right: 41.66667%;
  }
  .col-lg-pull-6 {
    right: 50%;
  }
  .col-lg-pull-7 {
    right: 58.33333%;
  }
  .col-lg-pull-8 {
    right: 66.66667%;
  }
  .col-lg-pull-9 {
    right: 75%;
  }
  .col-lg-pull-10 {
    right: 83.33333%;
  }
  .col-lg-pull-11 {
    right: 91.66667%;
  }
  .col-lg-pull-12 {
    right: 100%;
  }
  .col-lg-push-0 {
    left: auto;
  }
  .col-lg-push-1 {
    left: 8.33333%;
  }
  .col-lg-push-2 {
    left: 16.66667%;
  }
  .col-lg-push-3 {
    left: 25%;
  }
  .col-lg-push-4 {
    left: 33.33333%;
  }
  .col-lg-push-5 {
    left: 41.66667%;
  }
  .col-lg-push-6 {
    left: 50%;
  }
  .col-lg-push-7 {
    left: 58.33333%;
  }
  .col-lg-push-8 {
    left: 66.66667%;
  }
  .col-lg-push-9 {
    left: 75%;
  }
  .col-lg-push-10 {
    left: 83.33333%;
  }
  .col-lg-push-11 {
    left: 91.66667%;
  }
  .col-lg-push-12 {
    left: 100%;
  }
  .col-lg-offset-0 {
    margin-left: 0%;
  }
  .col-lg-offset-1 {
    margin-left: 8.33333%;
  }
  .col-lg-offset-2 {
    margin-left: 16.66667%;
  }
  .col-lg-offset-3 {
    margin-left: 25%;
  }
  .col-lg-offset-4 {
    margin-left: 33.33333%;
  }
  .col-lg-offset-5 {
    margin-left: 41.66667%;
  }
  .col-lg-offset-6 {
    margin-left: 50%;
  }
  .col-lg-offset-7 {
    margin-left: 58.33333%;
  }
  .col-lg-offset-8 {
    margin-left: 66.66667%;
  }
  .col-lg-offset-9 {
    margin-left: 75%;
  }
  .col-lg-offset-10 {
    margin-left: 83.33333%;
  }
  .col-lg-offset-11 {
    margin-left: 91.66667%;
  }
  .col-lg-offset-12 {
    margin-left: 100%;
  }
}

.pure-u,
.pure-u-1,
.pure-u-1-1,
.pure-u-1-2,
.pure-u-1-3,
.pure-u-2-3,
.pure-u-1-4,
.pure-u-3-4,
.pure-u-1-5,
.pure-u-2-5,
.pure-u-3-5,
.pure-u-4-5,
.pure-u-5-5,
.pure-u-1-6,
.pure-u-5-6,
.pure-u-1-8,
.pure-u-3-8,
.pure-u-5-8,
.pure-u-7-8,
.pure-u-1-12,
.pure-u-5-12,
.pure-u-7-12,
.pure-u-11-12,
.pure-u-1-24,
.pure-u-2-24,
.pure-u-3-24,
.pure-u-4-24,
.pure-u-5-24,
.pure-u-6-24,
.pure-u-7-24,
.pure-u-8-24,
.pure-u-9-24,
.pure-u-10-24,
.pure-u-11-24,
.pure-u-12-24,
.pure-u-13-24,
.pure-u-14-24,
.pure-u-15-24,
.pure-u-16-24,
.pure-u-17-24,
.pure-u-18-24,
.pure-u-19-24,
.pure-u-20-24,
.pure-u-21-24,
.pure-u-22-24,
.pure-u-23-24,
.pure-u-24-24 {
  float: left;
}

.pure-u-1-24 {
  width: 4.1667%;
  *width: 4.1357%;
}

.pure-u-1-12,
.pure-u-2-24 {
  width: 8.3333%;
  *width: 8.3023%;
}

.pure-u-1-8,
.pure-u-3-24 {
  width: 12.5000%;
  *width: 12.4690%;
}

.pure-u-1-6,
.pure-u-4-24 {
  width: 16.6667%;
  *width: 16.6357%;
}

.pure-u-1-5 {
  width: 20%;
  *width: 19.9690%;
}

.pure-u-5-24 {
  width: 20.8333%;
  *width: 20.8023%;
}

.pure-u-1-4,
.pure-u-6-24 {
  width: 25%;
  *width: 24.9690%;
}

.pure-u-7-24 {
  width: 29.1667%;
  *width: 29.1357%;
}

.pure-u-1-3,
.pure-u-8-24 {
  width: 33.3333%;
  *width: 33.3023%;
}

.pure-u-3-8,
.pure-u-9-24 {
  width: 37.5000%;
  *width: 37.4690%;
}

.pure-u-2-5 {
  width: 40%;
  *width: 39.9690%;
}

.pure-u-5-12,
.pure-u-10-24 {
  width: 41.6667%;
  *width: 41.6357%;
}

.pure-u-11-24 {
  width: 45.8333%;
  *width: 45.8023%;
}

.pure-u-1-2,
.pure-u-12-24 {
  width: 50%;
  *width: 49.9690%;
}

.pure-u-13-24 {
  width: 54.1667%;
  *width: 54.1357%;
}

.pure-u-7-12,
.pure-u-14-24 {
  width: 58.3333%;
  *width: 58.3023%;
}

.pure-u-3-5 {
  width: 60%;
  *width: 59.9690%;
}

.pure-u-5-8,
.pure-u-15-24 {
  width: 62.5000%;
  *width: 62.4690%;
}

.pure-u-2-3,
.pure-u-16-24 {
  width: 66.6667%;
  *width: 66.6357%;
}

.pure-u-17-24 {
  width: 70.8333%;
  *width: 70.8023%;
}

.pure-u-3-4,
.pure-u-18-24 {
  width: 75%;
  *width: 74.9690%;
}

.pure-u-19-24 {
  width: 79.1667%;
  *width: 79.1357%;
}

.pure-u-4-5 {
  width: 80%;
  *width: 79.9690%;
}

.pure-u-5-6,
.pure-u-20-24 {
  width: 83.3333%;
  *width: 83.3023%;
}

.pure-u-7-8,
.pure-u-21-24 {
  width: 87.5000%;
  *width: 87.4690%;
}

.pure-u-11-12,
.pure-u-22-24 {
  width: 91.6667%;
  *width: 91.6357%;
}

.pure-u-23-24 {
  width: 95.8333%;
  *width: 95.8023%;
}

.pure-u-1,
.pure-u-1-1,
.pure-u-5-5,
.pure-u-24-24 {
  width: 100%;
}

.module:before, .module:after,
.module__header:before,
.module__header:after,
.module__container:before,
.module__container:after,
.module__footer:before,
.module__footer:after {
  content: " ";
  display: table;
}

.module:after,
.module__header:after,
.module__container:after,
.module__footer:after {
  clear: both;
}

.module {
  margin-bottom: 40px;
}

.module__header {
  position: relative;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.module__header a:hover, .module__header a:focus {
  text-decoration: none;
}

.media_aside:before, .media_aside:after {
  content: " ";
  display: table;
}

.media_aside:after {
  clear: both;
}

.media_aside .media__object {
  float: left;
  width: 140px;
}

.media_aside .media__body {
  float: left;
}

html,
body {
  height: 100%;
}

html {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
  font-family: Roboto, "Helvetica Neue", "Microsoft YaHei", sans-serif;
  font-size: 16px;
  line-height: 1.5;
  color: #3f3f3f;
  background-color: #fff;
}

a {
  color: #3f3f3f;
  text-decoration: none;
}

a:hover, a:focus {
  color: #eb2837;
  text-decoration: underline;
}

a:focus {
  outline: 0 none transparent;
  outline-offset: 0;
}

img {
  vertical-align: middle;
}

.img-responsive {
  display: block;
  width: 100% \9;
  max-width: 100%;
  height: auto;
  margin-right: auto;
  margin-left: auto;
}

.img-fit {
  display: block;
  width: 100%;
}

.img-rounded {
  border-radius: 6px;
}

.img-circle {
  border-radius: 50%;
}

.text-primary {
  color: #eb2837 !important;
}

.text-gray1 {
  color: #292929 !important;
}

.text-gray2 {
  color: #3f3f3f !important;
}

.text-ellipsis {
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  word-wrap: normal;
  white-space: nowrap;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text_center {
  text-align: center;
}

hr {
  margin-top: 24px;
  margin-bottom: 24px;
  border: 0;
  border-top: 1px solid #e4e4e4;
}

.hr {
  display: block;
  clear: both;
  width: 100%;
  height: 20px;
  font: 0/0 a;
  background-color: #ebebeb;
}

.spacer {
  padding-right: 2px;
  padding-left: 2px;
  font-family: \5b8b\4f53, Simsun, sans-serif;
  font-size: 0.75em;
  color: #cccccc;
  cursor: default;
}

.clearfix:before, .clearfix:after {
  content: " ";
  display: table;
}

.clearfix:after {
  clear: both;
  overflow: hidden;
}

.clearfix {
  *zoom: 1;
}

.center-block {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.pull-left {
  float: left !important;
}

.pull-right {
  float: right !important;
}

.hidden,
.hide {
  display: none !important;
}

.show {
  display: block !important;
}

.page {
  position: relative;
  height: auto !important;
  height: 100%;
  min-height: 100%;
  overflow: hidden;
}

.page__container {
  position: relative;
}

.page__header {
  width: 100%;
  background-color: white;
  -webkit-box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.1);
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.1);
}

.page__header_pin {
  position: fixed;
  z-index: 1020;
  top: 0;
  right: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.8);
  -webkit-box-shadow: 1px 1px 1px rgba(126, 126, 126, 0.1);
  box-shadow: 1px 1px 1px rgba(126, 126, 126, 0.1);
  -webkit-animation: .8s linear headerPin;
  animation: .8s linear headerPin;
}

@-webkit-keyframes headerPin {
  0% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes headerPin {
  0% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

.header__section {
  position: relative;
  padding: 0 5%;
}

.header__logo {
  position: absolute;
  top: 50%;
  left: 5%;
  height: 80px;
  width: auto;
  margin-top: -40px;
}

.header__logo img {
  height: auto;
  max-height: 100%;
}

.nav {
  float: right;
}

.nav li {
  float: left;
  list-style: none;
}

.nav li a {
  display: block;
}

.nav li a:hover, .nav li a:focus {
  text-decoration: none;
}

.nav__item a {
  padding: 0 25px;
  height: 84px;
  line-height: 88px;
  border-bottom: 4px solid transparent;
  color: #2b2b2b;
}

.nav__item a:hover {
  border-bottom-color: #eb2837;
  color: #eb2837;
}

.nav__item a:focus {
  border-bottom-color: transparent;
  color: #2b2b2b;
}

.nav__item_active a, .nav__item_active a:hover, .nav__item_active a:focus {
  color: #eb2837;
  border-bottom-color: #eb2837;
}

.header__sign {
  height: 30px;
  margin-top: 25px;
  margin-left: 30px;
  padding: 0 .5em;
  line-height: 30px;
  background-color: #eb2837;
  color: #fff;
  border-radius: 15px;
}

.header__sign:hover, .header__sign:focus {
  color: #fff;
  background-color: #e41525;
}

@media (max-width: 767px) {
  .header__logo {
    top: 40px;
    height: 50px;
  }
  .navbar-collapse {
    margin-bottom: 15px;
  }
  .nav {
    float: none;
  }
  .nav li {
    float: none;
    text-align: center;
  }
  .nav li a {
    padding: 10px 15px;
    height: auto;
    line-height: normal;
  }
  .nav li .header__sign {
    width: 30%;
    margin-left: 30%;
    border-radius: 40px;
  }
  .nav .nav__item_active a {
    border-bottom-color: transparent;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .nav {
    font-size: 0.75em;
  }
  .nav .nav__item a {
    padding: 0 10px;
  }
}

.footer-service {
  padding: 20px 7.5%;
  background-color: #0f2958;
}

.footer-nav {
  float: left;
}

.footer-nav li {
  float: left;
}

.footer-nav li a {
  padding: 0 15px;
  font-size: 16px;
  font-weight: normal;
  text-align: left;
  color: #fff;
}

.footer-nav {
  float: right;
  width: 100%;
}

.footer-nav .nav__login {
  font-size: 0.94em;
  margin-top: 25px;
  padding: 0.3em 1.2em;
  height: 30px;
  line-height: 30px;
  background-color: #eb2836;
  color: #fff;
  border-radius: 15px;
}

.foot-partner {
  background-color: #d9d9d9;
  position: relative;
  padding: 2% 0;
}

.foot-partner__title {
  display: inline-block;
  position: absolute;
  top: 30%;
  font-size: 1.875em;
  font-weight: 300;
  color: #000;
}

.foot-partner__list {
  display: inline-block;
}

.foot-partner__list li {
  float: left;
}

.footer-contact {
  margin-top: 50px;
  border-top: 1px solid rgba(255, 255, 255, 0.39);
  border-bottom: 1px solid rgba(255, 255, 255, 0.39);
}

.contact-info {
  margin-top: 50px;
}

.contact-info__special {
  border-left: 1px solid rgba(255, 255, 255, 0.4);
}

.contact-info__special p {
  padding-left: 1em;
}

.contact-info__special p span {
  padding-left: 0.5em !important;
}

.contact-info__item,
.contact-social__title {
  font-size: 18px;
  text-align: left;
  color: #fff;
}

.contact-info__item {
  padding: 1em 1em 0.5em 0;
}

.contact-info__content p,
.contact-info__content p a {
  font-size: 14px;
  font-weight: normal;
  text-align: left;
  color: rgba(255, 255, 255, 0.6);
}

.contact-info__content p .iconfont,
.contact-info__content p a .iconfont {
  color: #eb2836;
}

.contact-info__content p span,
.contact-info__content p a span {
  padding-left: 1.5em;
}

.contact-info__unique {
  padding-left: 0 !important;
}

.contact-social {
  border-top: 1px solid rgba(255, 255, 255, 0.07);
  padding-top: 12px;
}

.contact-social__title {
  float: left;
  margin-right: 10px;
  padding: 0.5em 0;
}

.contact-social__item {
  float: left;
  padding: 0 0.3em;
}

.contact-social__item a {
  color: #006db7;
}

.contact-social__item a:focus, .contact-social__item a:hover {
  text-decoration: none;
  color: #047dce;
}

.contact-social__item .iconfont {
  font-size: 30px;
}

.contact-map {
  margin-top: 1em;
  margin-bottom: 1em;
}

.footer-copyright {
  padding: 30px 0;
  font-size: 0.8125em;
  font-weight: 300;
  text-align: center;
  color: #e0e0e0;
}

@media (max-width: 767px) {
  .foot-partner {
    padding-left: 20%;
  }
  .footer-contact {
    border-top: none;
  }
  .contact-map {
    display: none;
  }
  .contact-info__content {
    margin-bottom: 2em;
  }
  .contact-info__special {
    border-left: none;
  }
  .footer-contact .gutter {
    padding-left: 0;
    padding-right: 0;
    border-top: 1px solid rgba(255, 255, 255, 0.39);
  }
  .footer-contact .contact-info {
    margin-top: 0;
  }
  .footer-contact .contact-info .contact-info__special p {
    padding-left: 0;
  }
  .footer-contact .module {
    margin-bottom: 0;
  }
  .footer-nav li a {
    font-size: 0.75em;
    padding: 0 0.5em;
  }
  .footer-nav .nav__login {
    font-size: 0.5em;
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .contact-map {
    display: none;
  }
}

.hipaa {
  margin-top: -1.25rem;
}

.section:before, .section:after,
.section__wrapper:before,
.section__wrapper:after,
.section__header:before,
.section__header:after,
.section__container:before,
.section__container:after {
  content: " ";
  display: table;
}

.section:after,
.section__wrapper:after,
.section__header:after,
.section__container:after {
  clear: both;
  overflow: hidden;
}

.section,
.section__wrapper,
.section__header,
.section__container {
  *zoom: 1;
}

.section__wrapper {
  max-width: 1230px;
  margin-right: auto;
  margin-left: auto;
}

@media (max-width: 1280px) {
  .section__wrapper {
    padding-right: 5%;
    padding-left: 5%;
    width: 90%;
  }
}

.section__header {
  position: relative;
  margin-bottom: 10px;
}

.section__header a,
.section__header a:hover,
.section__header a:focus {
  text-decoration: none;
}

.section__title {
  font-size: 20px;
}

.section__title a,
.section__title a:hover,
.section__title a:focus {
  color: #8b8b8b;
}

.section__container {
  clear: both;
}

.pure-wrapper {
  margin-right: -35px;
  margin-left: -35px;
}

.pin {
  position: fixed;
  z-index: 1020;
  -webkit-animation: pin 1s ease-in-out;
  animation: pin 1s ease-in-out;
}

@-webkit-keyframes pin {
  0% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes pin {
  0% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

.nav {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav > li {
  position: relative;
  display: block;
}

.nav > li > a {
  position: relative;
  display: block;
}

.nav > li > a:hover,
.nav > li > a:focus {
  text-decoration: none;
}

.nav > li.disabled > a {
  color: #777;
}

.nav > li.disabled > a:hover,
.nav > li.disabled > a:focus {
  color: #777;
  text-decoration: none;
  cursor: not-allowed;
  background-color: transparent;
}

.nav .open > a,
.nav .open > a:hover,
.nav .open > a:focus {
  background-color: #eee;
  border-color: #337ab7;
}

.nav .nav-divider {
  height: 1px;
  margin: 9px 0;
  overflow: hidden;
  background-color: #e5e5e5;
}

.nav > li > a > img {
  max-width: none;
}

.navbar {
  display: none;
}

.dropup .caret,
.navbar-fixed-bottom .dropdown .caret {
  content: "";
  border-top: 0;
  border-bottom: 4px dashed;
  border-bottom: 4px solid \9;
}

.dropup .dropdown-menu,
.navbar-fixed-bottom .dropdown .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-bottom: 2px;
}

@media (min-width: 768px) {
  .navbar-right .dropdown-menu {
    right: 0;
    left: auto;
  }
  .navbar-right .dropdown-menu-left {
    right: auto;
    left: 0;
  }
}

.navbar {
  position: relative;
  min-height: 50px;
  margin-bottom: 20px;
  border: 1px solid transparent;
}

@media (min-width: 768px) {
  .navbar {
    border-radius: 4px;
  }
}

@media (min-width: 768px) {
  .navbar-header {
    float: left;
  }
}

.navbar-collapse {
  padding-right: 15px;
  padding-left: 15px;
  overflow-x: visible;
  -webkit-overflow-scrolling: touch;
  border-top: 1px solid transparent;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
}

.navbar-collapse.in {
  overflow-y: auto;
}

@media (min-width: 768px) {
  .navbar-collapse {
    width: auto;
    border-top: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .navbar-collapse.collapse {
    display: block !important;
    height: auto !important;
    padding-bottom: 0;
    overflow: visible !important;
  }
  .navbar-collapse.in {
    overflow-y: visible;
  }
  .navbar-fixed-top .navbar-collapse,
  .navbar-static-top .navbar-collapse,
  .navbar-fixed-bottom .navbar-collapse {
    padding-right: 0;
    padding-left: 0;
  }
}

.navbar-fixed-top .navbar-collapse,
.navbar-fixed-bottom .navbar-collapse {
  max-height: 340px;
}

@media (max-device-width: 480px) and (orientation: landscape) {
  .navbar-fixed-top .navbar-collapse,
  .navbar-fixed-bottom .navbar-collapse {
    max-height: 200px;
  }
}

.container > .navbar-header,
.container-fluid > .navbar-header,
.container > .navbar-collapse,
.container-fluid > .navbar-collapse {
  margin-right: -15px;
  margin-left: -15px;
}

@media (min-width: 768px) {
  .container > .navbar-header,
  .container-fluid > .navbar-header,
  .container > .navbar-collapse,
  .container-fluid > .navbar-collapse {
    margin-right: 0;
    margin-left: 0;
  }
}

.navbar-static-top {
  z-index: 1000;
  border-width: 0 0 1px;
}

@media (min-width: 768px) {
  .navbar-static-top {
    border-radius: 0;
  }
}

.navbar-fixed-top,
.navbar-fixed-bottom {
  position: fixed;
  right: 0;
  left: 0;
  z-index: 1030;
}

@media (min-width: 768px) {
  .navbar-fixed-top,
  .navbar-fixed-bottom {
    border-radius: 0;
  }
}

.navbar-fixed-top {
  top: 0;
  border-width: 0 0 1px;
}

.navbar-fixed-bottom {
  bottom: 0;
  margin-bottom: 0;
  border-width: 1px 0 0;
}

.navbar-brand {
  float: left;
  height: 50px;
  padding: 15px 15px;
  font-size: 18px;
  line-height: 20px;
}

.navbar-brand:hover,
.navbar-brand:focus {
  text-decoration: none;
}

.navbar-brand > img {
  display: block;
}

@media (min-width: 768px) {
  .navbar > .container .navbar-brand,
  .navbar > .container-fluid .navbar-brand {
    margin-left: -15px;
  }
}

.navbar-toggle {
  position: relative;
  float: right;
  padding: 9px 10px;
  margin-top: 8px;
  margin-right: 15px;
  margin-bottom: 8px;
  background-color: transparent;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
}

.navbar-toggle:focus {
  outline: 0;
}

.navbar-toggle .icon-bar {
  display: block;
  width: 22px;
  height: 2px;
  border-radius: 1px;
}

.navbar-toggle .icon-bar + .icon-bar {
  margin-top: 4px;
}

@media (min-width: 768px) {
  .navbar-toggle {
    display: none;
  }
}

.navbar-nav {
  margin: 7.5px -15px;
}

.navbar-nav > li > a {
  padding-top: 10px;
  padding-bottom: 10px;
  line-height: 20px;
}

@media (max-width: 767px) {
  .navbar-nav .open .dropdown-menu {
    position: static;
    float: none;
    width: auto;
    margin-top: 0;
    background-color: transparent;
    border: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .navbar-nav .open .dropdown-menu > li > a,
  .navbar-nav .open .dropdown-menu .dropdown-header {
    padding: 5px 15px 5px 25px;
  }
  .navbar-nav .open .dropdown-menu > li > a {
    line-height: 20px;
  }
  .navbar-nav .open .dropdown-menu > li > a:hover,
  .navbar-nav .open .dropdown-menu > li > a:focus {
    background-image: none;
  }
}

@media (min-width: 768px) {
  .navbar-nav {
    float: left;
    margin: 0;
  }
  .navbar-nav > li {
    float: left;
  }
  .navbar-nav > li > a {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}

.navbar-form {
  padding: 10px 15px;
  margin-top: 8px;
  margin-right: -15px;
  margin-bottom: 8px;
  margin-left: -15px;
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1), 0 1px 0 rgba(255, 255, 255, 0.1);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1), 0 1px 0 rgba(255, 255, 255, 0.1);
}

@media (min-width: 768px) {
  .navbar-form .form-group {
    display: inline-block;
    margin-bottom: 0;
    vertical-align: middle;
  }
  .navbar-form .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .navbar-form .form-control-static {
    display: inline-block;
  }
  .navbar-form .input-group {
    display: inline-table;
    vertical-align: middle;
  }
  .navbar-form .input-group .input-group-addon,
  .navbar-form .input-group .input-group-btn,
  .navbar-form .input-group .form-control {
    width: auto;
  }
  .navbar-form .input-group > .form-control {
    width: 100%;
  }
  .navbar-form .control-label {
    margin-bottom: 0;
    vertical-align: middle;
  }
  .navbar-form .radio,
  .navbar-form .checkbox {
    display: inline-block;
    margin-top: 0;
    margin-bottom: 0;
    vertical-align: middle;
  }
  .navbar-form .radio label,
  .navbar-form .checkbox label {
    padding-left: 0;
  }
  .navbar-form .radio input[type="radio"],
  .navbar-form .checkbox input[type="checkbox"] {
    position: relative;
    margin-left: 0;
  }
  .navbar-form .has-feedback .form-control-feedback {
    top: 0;
  }
}

@media (max-width: 767px) {
  .navbar-form .form-group {
    margin-bottom: 5px;
  }
  .navbar-form .form-group:last-child {
    margin-bottom: 0;
  }
}

@media (min-width: 768px) {
  .navbar-form {
    width: auto;
    padding-top: 0;
    padding-bottom: 0;
    margin-right: 0;
    margin-left: 0;
    border: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
}

.navbar-nav > li > .dropdown-menu {
  margin-top: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.navbar-fixed-bottom .navbar-nav > li > .dropdown-menu {
  margin-bottom: 0;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.navbar-btn {
  margin-top: 8px;
  margin-bottom: 8px;
}

.navbar-btn.btn-sm {
  margin-top: 10px;
  margin-bottom: 10px;
}

.navbar-btn.btn-xs {
  margin-top: 14px;
  margin-bottom: 14px;
}

.navbar-text {
  margin-top: 15px;
  margin-bottom: 15px;
}

@media (min-width: 768px) {
  .navbar-text {
    float: left;
    margin-right: 15px;
    margin-left: 15px;
  }
}

@media (min-width: 768px) {
  .navbar-left {
    float: left !important;
  }
  .navbar-right {
    float: right !important;
    margin-right: -15px;
  }
  .navbar-right ~ .navbar-right {
    margin-right: 0;
  }
}

.navbar-default {
  background-color: #f8f8f8;
  border-color: #e7e7e7;
}

.navbar-default .navbar-brand {
  color: #777;
}

.navbar-default .navbar-brand:hover,
.navbar-default .navbar-brand:focus {
  color: #5e5e5e;
  background-color: transparent;
}

.navbar-default .navbar-text {
  color: #777;
}

.navbar-default .navbar-nav > li > a {
  color: #777;
}

.navbar-default .navbar-nav > li > a:hover,
.navbar-default .navbar-nav > li > a:focus {
  color: #333;
  background-color: transparent;
}

.navbar-default .navbar-nav > .active > a,
.navbar-default .navbar-nav > .active > a:hover,
.navbar-default .navbar-nav > .active > a:focus {
  color: #555;
  background-color: #e7e7e7;
}

.navbar-default .navbar-nav > .disabled > a,
.navbar-default .navbar-nav > .disabled > a:hover,
.navbar-default .navbar-nav > .disabled > a:focus {
  color: #ccc;
  background-color: transparent;
}

.navbar-default .navbar-toggle {
  border-color: #ddd;
}

.navbar-default .navbar-toggle:hover,
.navbar-default .navbar-toggle:focus {
  background-color: #ddd;
}

.navbar-default .navbar-toggle .icon-bar {
  background-color: #888;
}

.navbar-default .navbar-collapse,
.navbar-default .navbar-form {
  border-color: #e7e7e7;
}

.navbar-default .navbar-nav > .open > a,
.navbar-default .navbar-nav > .open > a:hover,
.navbar-default .navbar-nav > .open > a:focus {
  color: #555;
  background-color: #e7e7e7;
}

@media (max-width: 767px) {
  .navbar-default .navbar-nav .open .dropdown-menu > li > a {
    color: #777;
  }
  .navbar-default .navbar-nav .open .dropdown-menu > li > a:hover,
  .navbar-default .navbar-nav .open .dropdown-menu > li > a:focus {
    color: #333;
    background-color: transparent;
  }
  .navbar-default .navbar-nav .open .dropdown-menu > .active > a,
  .navbar-default .navbar-nav .open .dropdown-menu > .active > a:hover,
  .navbar-default .navbar-nav .open .dropdown-menu > .active > a:focus {
    color: #555;
    background-color: #e7e7e7;
  }
  .navbar-default .navbar-nav .open .dropdown-menu > .disabled > a,
  .navbar-default .navbar-nav .open .dropdown-menu > .disabled > a:hover,
  .navbar-default .navbar-nav .open .dropdown-menu > .disabled > a:focus {
    color: #ccc;
    background-color: transparent;
  }
}

.navbar-default .navbar-link {
  color: #777;
}

.navbar-default .navbar-link:hover {
  color: #333;
}

.navbar-default .btn-link {
  color: #777;
}

.navbar-default .btn-link:hover,
.navbar-default .btn-link:focus {
  color: #333;
}

.navbar-default .btn-link[disabled]:hover,
fieldset[disabled] .navbar-default .btn-link:hover,
.navbar-default .btn-link[disabled]:focus,
fieldset[disabled] .navbar-default .btn-link:focus {
  color: #ccc;
}

.navbar-inverse {
  background-color: #222;
  border-color: #080808;
}

.navbar-inverse .navbar-brand {
  color: #9d9d9d;
}

.navbar-inverse .navbar-brand:hover,
.navbar-inverse .navbar-brand:focus {
  color: #fff;
  background-color: transparent;
}

.navbar-inverse .navbar-text {
  color: #9d9d9d;
}

.navbar-inverse .navbar-nav > li > a {
  color: #9d9d9d;
}

.navbar-inverse .navbar-nav > li > a:hover,
.navbar-inverse .navbar-nav > li > a:focus {
  color: #fff;
  background-color: transparent;
}

.navbar-inverse .navbar-nav > .active > a,
.navbar-inverse .navbar-nav > .active > a:hover,
.navbar-inverse .navbar-nav > .active > a:focus {
  color: #fff;
  background-color: #080808;
}

.navbar-inverse .navbar-nav > .disabled > a,
.navbar-inverse .navbar-nav > .disabled > a:hover,
.navbar-inverse .navbar-nav > .disabled > a:focus {
  color: #444;
  background-color: transparent;
}

.navbar-inverse .navbar-toggle {
  border-color: #333;
}

.navbar-inverse .navbar-toggle:hover,
.navbar-inverse .navbar-toggle:focus {
  background-color: #333;
}

.navbar-inverse .navbar-toggle .icon-bar {
  background-color: #fff;
}

.navbar-inverse .navbar-collapse,
.navbar-inverse .navbar-form {
  border-color: #101010;
}

.navbar-inverse .navbar-nav > .open > a,
.navbar-inverse .navbar-nav > .open > a:hover,
.navbar-inverse .navbar-nav > .open > a:focus {
  color: #fff;
  background-color: #080808;
}

@media (max-width: 767px) {
  .navbar-inverse .navbar-nav .open .dropdown-menu > .dropdown-header {
    border-color: #080808;
  }
  .navbar-inverse .navbar-nav .open .dropdown-menu .divider {
    background-color: #080808;
  }
  .navbar-inverse .navbar-nav .open .dropdown-menu > li > a {
    color: #9d9d9d;
  }
  .navbar-inverse .navbar-nav .open .dropdown-menu > li > a:hover,
  .navbar-inverse .navbar-nav .open .dropdown-menu > li > a:focus {
    color: #fff;
    background-color: transparent;
  }
  .navbar-inverse .navbar-nav .open .dropdown-menu > .active > a,
  .navbar-inverse .navbar-nav .open .dropdown-menu > .active > a:hover,
  .navbar-inverse .navbar-nav .open .dropdown-menu > .active > a:focus {
    color: #fff;
    background-color: #080808;
  }
  .navbar-inverse .navbar-nav .open .dropdown-menu > .disabled > a,
  .navbar-inverse .navbar-nav .open .dropdown-menu > .disabled > a:hover,
  .navbar-inverse .navbar-nav .open .dropdown-menu > .disabled > a:focus {
    color: #444;
    background-color: transparent;
  }
}

.navbar-inverse .navbar-link {
  color: #9d9d9d;
}

.navbar-inverse .navbar-link:hover {
  color: #fff;
}

.navbar-inverse .btn-link {
  color: #9d9d9d;
}

.navbar-inverse .btn-link:hover,
.navbar-inverse .btn-link:focus {
  color: #fff;
}

.navbar-inverse .btn-link[disabled]:hover,
fieldset[disabled] .navbar-inverse .btn-link:hover,
.navbar-inverse .btn-link[disabled]:focus,
fieldset[disabled] .navbar-inverse .btn-link:focus {
  color: #444;
}

.nav:before,
.nav:after,
.navbar:before,
.navbar:after,
.navbar-header:before,
.navbar-header:after,
.navbar-collapse:before,
.navbar-collapse:after,
.pager:before,
.pager:after,
.panel-body:before,
.panel-body:after,
.modal-header:before,
.modal-header:after,
.modal-footer:before,
.modal-footer:after {
  display: table;
  content: " ";
}

.nav:after,
.navbar:after,
.navbar-header:after,
.navbar-collapse:after,
.pager:after,
.panel-body:after,
.modal-header:after,
.modal-footer:after {
  clear: both;
}

.collapse {
  display: none;
}

.collapse.in {
  display: block;
}

.section__header-shape {
  position: relative;
  margin-top: -3.125em;
  margin-bottom: 3.125em;
}

.section__header-shape .title {
  position: relative;
  z-index: 2;
  font-size: 2.1875em;
  font-weight: 300;
  text-align: center;
  color: #fff;
  padding: 0.625em 0;
}

.section__header-shape .shape {
  height: 0;
  display: block;
  position: absolute;
  z-index: 1;
  right: 0;
  left: 0;
  border-right: 45px solid transparent;
  border-bottom-style: solid;
  border-bottom-width: 90px;
  border-left: 45px solid transparent;
}

.section__style {
  border-top-width: 2px;
  border-top-style: solid;
}

.section__style_1 {
  border-top-color: #eb2836;
}

.section__style_1 .section__header-shape .shape {
  border-bottom-color: #eb2836;
}

.section__style_2 {
  border-top-color: #2d302f;
}

.section__style_2 .section__header-shape .shape {
  border-bottom-color: #2d302f;
}

.section__style_2 div.module:nth-of-type(1) {
  padding-bottom: 40px;
}

.section__style_3 {
  border-top-color: #3a85ca;
}

.section__style_3 .section__header-shape .shape {
  border-bottom-color: #3a85ca;
}

.section__body {
  padding: 50px 0;
}

.section__body p {
  padding-bottom: 30px;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.56;
  text-align: left;
  color: #1c1c1c;
}

.intelligence p:nth-of-type(odd) {
  line-height: 1.75;
  font-weight: 300;
  color: #666666;
}

.intelligence p:nth-of-type(even) {
  line-height: 1.75;
  font-weight: bold;
  color: #666666;
}

.descrip__title {
  padding-bottom: 30px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 1.875em;
  font-weight: 500;
  text-align: center;
  color: #e92836;
}

.question__btn {
  padding: 23px 78px;
  border-radius: 30px;
  font-size: 17px;
  line-height: 3.15;
  text-align: center;
  background-color: #2d302f;
}

.question__btn a {
  color: #fff;
}

.solution__title-shap {
  position: relative;
  text-align: center;
}

.solution__title-shap .section-solution__title {
  position: relative;
  z-index: 2;
  top: 12px;
  margin: 0 auto;
  margin-bottom: 30px;
  padding: 2px 0;
  border-radius: 30px;
  width: 300px;
  height: 35px;
  font-size: 16px;
  font-weight: bold;
  line-height: 35px;
  text-align: center;
  color: #000000;
}

.solution__title-shap .shape__line {
  position: absolute;
  z-index: 1;
  margin: 0 auto;
  margin-top: 30px;
  height: 2px;
  width: 100%;
  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0), #3a85ca, rgba(255, 255, 255, 0));
  background: -webkit-gradient(linear, left top, right top, from(rgba(255, 255, 255, 0)), color-stop(#3a85ca), to(rgba(255, 255, 255, 0)));
  background: -o-linear-gradient(left, rgba(255, 255, 255, 0), #3a85ca, rgba(255, 255, 255, 0));
  background: linear-gradient(to right, rgba(255, 255, 255, 0), #3a85ca, rgba(255, 255, 255, 0));
}

.section-solution__title,
.section-solution__contain {
  padding: 10%;
  height: 18.75em;
  border-style: solid;
  border-width: 1px;
  -webkit-box-shadow: 1.5px 2.6px 7px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 1.5px 2.6px 7px 0 rgba(0, 0, 0, 0.1);
}

.section-solution__title_style1 .section-solution__title,
.section-solution__title_style1 .section-solution__contain {
  border-color: #91b2d1;
  background-color: #b7d2ec;
}

.section-solution__title_style1 .gutter_small {
  margin-bottom: 1em;
}

.section-solution__title_style2 .section-solution__title,
.section-solution__title_style2 .section-solution__contain {
  border-color: #bcc6d5;
  background-color: #e4e9f1;
}

.section-solution__title_style3 .section-solution__title,
.section-solution__title_style3 .section-solution__contain {
  border-color: #d7cfa5;
  background-color: #e7e5db;
}

.section-solution__title_style4 .section-solution__title,
.section-solution__title_style4 .section-solution__contain {
  border-color: #bcbcb9;
  background-color: #dcdcdb;
}

.section-solution__contain dt {
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.67;
  color: #0d4375;
}

.section-solution__contain dd {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.71;
  color: #0d4375;
}

.wrapper-top {
  margin-top: 50px;
}

.index-poster {
  position: relative;
  width: 100%;
  height: 890px;
  overflow: hidden;
  margin-right: auto;
  margin-left: auto;
}

.index-poster .index-poster__container {
  width: 100%;
}

.index-poster .index-poster__pagination {
  position: absolute;
  bottom: 80px;
  left: 5%;
  z-index: 9;
}

.index-poster .swiper-pagination-switch {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: solid 3px #00a1f1;
  border-radius: 50%;
  background: transparent;
  margin: 0 8px;
  cursor: pointer;
  -webkit-transition: background-color .2s linear;
  -o-transition: background-color .2s linear;
  transition: background-color .2s linear;
}

.index-poster .swiper-pagination-switch:focus {
  outline: none;
}

.index-poster .swiper-active-switch {
  border-color: #eb2836;
  background-color: #eb2836;
  outline: none;
}

.index-poster__slide {
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.index-poster__slide .text {
  position: absolute;
  top: 32%;
  left: 5%;
}

.index-poster__slide .text span {
  display: block;
  line-height: 1;
  margin-bottom: 15px;
}

.index-poster__slide .text .text-gray2 {
  font-size: 1.5em;
}

.index-poster__slide .text .text-gray1,
.index-poster__slide .text .text-primary {
  font-size: 2.875em;
}

.index-poster__slide1 {
  background-image: url("../img/index-poster-1.jpg");
}

.index-poster__slide2 {
  background-image: url("../img/index-poster-2.jpg");
}

.index-poster__slide3 {
  background-image: url("../img/index-poster-3.jpg");
}

@media (max-width: 999px) {
  .index-poster {
    height: 620px;
  }
  .index-poster .index-poster__pagination {
    bottom: 50px;
  }
  .index-poster__slide1 {
    background-image: url("../img/index-poster-1-md.jpg");
  }
  .index-poster__slide2 {
    background-image: url("../img/index-poster-2-md.jpg");
  }
  .index-poster__slide3 {
    background-image: url("../img/index-poster-3-md.jpg");
  }
}

@media (max-width: 767px) {
  .section__header-shape .title {
    font-size: 1.125em;
  }
  .index-section_1 .title {
    padding: 1.8em 0;
  }
  .index-section_2 .title {
    padding: 1em 0;
  }
  .index-section_3 .title,
  .index-section_4 .title {
    padding: 0.2em 0;
  }
  .index-poster__slide .text .text-gray2 {
    font-size: 0.75em;
  }
  .index-poster__slide .text .text-gray1,
  .index-poster__slide .text .text-primary {
    font-size: 1.25em;
  }
  .index-poster .swiper-pagination-switch {
    width: 10px;
    height: 10px;
    border: solid 1px #00a1f1;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .section__header-shape .title {
    font-size: 1.5em;
  }
}

@media (min-width: 992px) and (max-width: 1191px) {
  .section__header-shape .title {
    font-size: 1.75em;
  }
}

.company-poster {
  background: url(../img/company-poster.jpg) no-repeat center center;
  height: 500px;
  background-size: cover;
}

@media (max-width: 767px) {
  .company-poster {
    height: 150px;
  }
}

.section__body .img-wrapper {
  padding-bottom: 0;
}

.section__body .img-wrapper p {
  padding-bottom: 0;
}

.overview__title {
  font-size: 1.875em;
  font-weight: 500;
  line-height: 0.83;
  text-align: left;
  color: #eb2837;
  margin-top: 50px;
}

.overview__contain {
  font-size: 16px;
  font-weight: 300;
  line-height: 1.56;
  text-align: left;
  color: #1c1c1c;
}

.photo-album {
  font-size: 2.625em;
  font-weight: 300;
  line-height: 0.6;
  text-align: center;
  color: #000000;
  margin-top: 60px;
}

.img-wrapper {
  padding: 0 15%;
}

.img-wrapper .gutter {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  max-width: 240px;
}

.img-wrapper .gutter .img-title {
  position: absolute;
  width: 65%;
  height: 50px;
  bottom: 10px;
  background-color: #746f6f;
}

.img-wrapper .gutter .img-title-specific {
  width: 75%;
}

.img-title {
  font-weight: normal;
}

.img-title p:nth-of-type(1) {
  height: 14px;
  font-size: 14px;
  line-height: 3.07;
  color: #fff;
  padding-left: 10px;
}

.img-title p:nth-of-type(2) {
  height: 9px;
  font-size: 0.6875em;
  line-height: 3.91;
  color: rgba(255, 255, 255, 0.7);
  padding-left: 10px;
}

.modal {
  max-width: 1080px !important;
  border-radius: 0 !important;
  padding: 30px 30px !important;
}

.leader-modal {
  width: 80%;
  min-height: 400px;
  padding: 20px 5%;
  margin: 0 auto;
  background-color: #fff;
  -webkit-box-shadow: 0px 2px 49px 0 rgba(218, 218, 218, 0.8);
  box-shadow: 0px 2px 49px 0 rgba(218, 218, 218, 0.8);
  position: relative;
}

.modal__close {
  position: absolute;
  top: 20px;
  right: 30px;
  opacity: 0.5;
  filter: alpha(opacity=50);
  z-index: 999;
}

.modal__close:hover {
  color: #464444;
  text-decoration: none;
  opacity: 0.7;
  filter: alpha(opacity=70);
}

.modal__close .iconfont {
  font-size: 1.25em;
}

.leader-modal .pure-u-1-1 {
  text-align: right;
}

.leader-modal {
  text-align: left;
}

.leader-modal .leader-modal__name {
  font-size: 18px;
  font-weight: 500;
  color: #1c65b0;
}

.leader-modal .leader-modal__title {
  font-size: 14px;
  font-weight: normal;
  color: rgba(30, 23, 13, 0.5);
  padding: 5px 0;
}

.leader-modal .leader-modal__introduce {
  font-size: 16px;
  font-weight: normal;
  line-height: 1.5;
  color: rgba(30, 23, 13, 0.8);
}

.blocker {
  z-index: 1050 !important;
}

.leader-modal__info {
  padding-left: 20px;
}

.leader-modal__meta {
  margin-bottom: 10px;
}

.leader-modal__meta a,
.leader-modal__meta a:hover {
  text-decoration: none;
}

.leader-modal__meta a {
  color: #808080;
}

.leader-modal__meta a:hover {
  color: #eb2837;
}

.leader-modal__meta .iconfont {
  padding: 3px;
  font-size: 1.25em;
  opacity: 0.5;
  filter: alpha(opacity=50);
}

.leader-modal__introduce {
  text-align: justify;
}

@media (max-width: 767px) {
  .photo-album {
    font-size: 2.25em;
  }
  .js_leaderModal {
    margin-bottom: 20px;
  }
  .leader-modal .leader-modal__info {
    padding-left: 0;
  }
  .leader-modal .leader-modal__info .leader-modal__introduce {
    font-size: 0.75em;
    text-align: left;
  }
  .img-wrapper .module {
    margin-bottom: 0;
  }
  .img-wrapper .module-sub {
    margin: 0;
  }
}

@media (max-width: 767px) {
  .js_leaderModal {
    margin-bottom: 20px;
  }
  .img-wrapper .gutter .img-title-specific {
    width: 70%;
    font-size: 0.9em;
  }
}

.module-sub {
  margin: 0 -15% 40px -15%;
}

.news-poster {
  margin-bottom: 30px;
  background: url(../img/news-poster.jpg) no-repeat center center;
  height: 500px;
  background-size: cover;
}

@media (max-width: 767px) {
  .news-poster {
    background: url(../img/news-poster-xs.jpg) no-repeat center center;
    height: 150px;
    background-size: 100%;
  }
}

.news-list__header {
  margin-bottom: 30px;
  font-size: 1.875em;
  font-weight: 500;
  color: #eb2837;
}

.news-list__archive {
  margin-bottom: 30px;
  margin-top: 30px;
  font-size: 1.5em;
  font-weight: normal;
  color: #000;
}

.news-list__item .media__object {
  width: 120px;
  height: 80px;
  background: url("../img/news-list-placeholder.png") no-repeat center center;
  background-size: contain;
}

.news-list__item .col-md-10.col-lg-10 {
  margin-left: 5%;
}

.news-list__item .col-md-10.col-lg-10 .media__body {
  padding-left: 20px;
  padding-right: 20px;
}

.news-list__date {
  font-size: 0.875em;
  color: #414141;
}

.news-list__title {
  font-size: 1.125em;
}

.news-list__title a {
  color: #eb2837;
}

.news-list__meta {
  font-size: 0.875em;
  color: #353535;
}

@media (max-width: 767px) {
  .news-list__item .col-md-10.col-lg-10 {
    margin-left: 0;
  }
  .news-list__item .col-md-10.col-lg-10 .media__body {
    padding-left: 0;
    padding-right: 0;
    padding-top: 20px;
    margin-left: 0;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .news-list__item .col-md-10.col-lg-10 {
    margin-left: 0;
  }
  .news-list__item .col-md-10.col-lg-10 .media__body {
    padding-left: 0;
    padding-right: 0;
    padding-top: 20px;
  }
}

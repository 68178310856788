//
// Section, 基础模块
// --------------------------------------------------
.section,
.section__wrapper,
.section__header,
.section__container {
  @include clearfix-ie;
}

.section {}

.section__wrapper {
  max-width: 1230px;
  margin-right: auto;
  margin-left: auto;
}

@media (max-width: 1280px) {
  .section__wrapper {
    padding-right: 5%;
    padding-left: 5%;
    width: 90%;
  }
}

.section__header {
  position: relative;
  margin-bottom: $margin-base-vertical;

  a,
  a:hover,
  a:focus {
    text-decoration: none;
  }
}

.section__title {
  font-size: $font-size-large;

  a,
  a:hover,
  a:focus {
    color: $gray-light;
  }
}

.section__aside {}

.section__container {
  clear: both;
}

.pure-wrapper {
  margin-right: -35px;
  margin-left: -35px;
}
//
// Module, 基础模块
// --------------------------------------------------
.module,
.module__header,
.module__container,
.module__footer {
  @include clearfix;
}

.module {
  margin-bottom: $margin-large-vertical * 2;
}

.module__header {
  position: relative;
  padding-top: $padding-base-vertical;
  padding-bottom: $padding-base-vertical;
  margin-bottom: $margin-base-vertical;
  a {
    &:hover,
    &:focus {
      text-decoration: none;
    }
  }
}


.media_aside {
  @include clearfix;
  .media__object {
    float: left;
    width: 140px;
  }
  .media__body {
    float: left;
  }
}
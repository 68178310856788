// Text overflow
// Requires inline-block or block for proper styling

@mixin text-ellipsis() {
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: normal;
    white-space: nowrap;
}

// 文字超过多少行就...(仅webkit内核)
@mixin multiline-text-ellipsis($line){
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: normal;
  display: -webkit-box;
  -webkit-line-clamp: $line;
  -webkit-box-orient: vertical;
}

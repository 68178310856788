.company-poster {
  background: url(../img/company-poster.jpg) no-repeat center center;
  height: 500px;
  background-size: cover;
}

@media (max-width:767px) {
  .company-poster {
    height: 150px;
  }
}

.section__body .img-wrapper {
  padding-bottom: 0;
}

.section__body .img-wrapper p {
  padding-bottom: 0;
}

.overview__title {
  font-size: 1.875em;
  font-weight: 500;
  line-height: 0.83;
  text-align: left;
  color: #eb2837;
  margin-top: 50px;
}

.overview__contain {
  font-size: $font-size-base;
  font-weight: 300;
  line-height: 1.56;
  text-align: left;
  color: #1c1c1c;
}

.photo-album {
  font-size: 2.625em;
  font-weight: 300;
  line-height: 0.6;
  text-align: center;
  color: #000000;
  margin-top: 60px;
}

.img-wrapper {
  padding: 0 15%;
}

.img-wrapper .gutter {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  max-width: 240px;
}

.img-wrapper .gutter .img-title {
  position: absolute;
  width: 65%;
  height: 50px;
  bottom: 10px;
  background-color: #746f6f;
}

.img-wrapper .gutter .img-title-specific {
 width: 75%;
}

.img-title {
  font-weight: normal;

  p:nth-of-type(1) {
    height: 14px;
    font-size: $font-size-normal;
    line-height: 3.07;
    color: #fff;
    padding-left: 10px;
  }

  p:nth-of-type(2) {
    height: 9px;
    font-size: 0.6875em;
    line-height: 3.91;
    color: rgba(255, 255, 255, 0.7);
    padding-left: 10px;
  }
}



.modal {
  max-width: 1080px !important;
  border-radius: 0 !important;
  padding: 30px 30px !important;
}

.leader-modal {
  width: 80%;
  min-height: 400px;
  padding: 20px 5%;
  margin: 0 auto;
  background-color: #fff;
  box-shadow: 0px 2px 49px 0 rgba(218, 218, 218, 0.8);
  position: relative;
}

.modal__close {
  position: absolute;
  top: 20px;
  right: 30px;
  @include opacity(.5);
  z-index: 999;

  &:hover {
    color: #464444;
    text-decoration: none;
    @include opacity(.7);
  }

  .iconfont {
    font-size: 1.25em;
  }
}

// .leader-modal__avatar img {
//   height: 250px;
// }

.leader-modal .pure-u-1-1 {
  text-align: right;
}

.leader-modal {
  text-align: left;

  .leader-modal__name {
    font-size: $font-size-medium;
    font-weight: 500;
    color: #1c65b0;
  }

  .leader-modal__title {
    font-size: $font-size-normal;
    font-weight: normal;
    color: rgba(30, 23, 13, 0.5);
    padding: 5px 0;
  }

  .leader-modal__introduce {
    font-size: $font-size-base;
    font-weight: normal;
    line-height: 1.5;
    color: rgba(30, 23, 13, 0.8);
  }
}

.blocker {
  z-index: $zindex-modal !important;
}

.leader-modal__info {
  padding-left: 20px;
}

.leader-modal__meta {
  margin-bottom: 10px;
  a,
  a:hover {
    text-decoration: none;
  }

  a {
    color: #808080;

    &:hover {
      color: $brand-primary;
    }
  }

  .iconfont {
    padding: 3px;
    font-size: 1.25em;
    @include opacity(.5);
  }
}

.leader-modal__introduce {
  text-align: justify;
}

@media (max-width:767px) {
  .photo-album {
    font-size: 2.25em;
  }
  .js_leaderModal {
    margin-bottom: 20px;
  }
  .leader-modal {
    // max-height: 30em;
    // overflow: auto;
    .leader-modal__info {
      padding-left: 0;
      .leader-modal__introduce {
        font-size: 0.75em;
        text-align: left;
      }
    }     
  }
  .img-wrapper {
    .module {
      margin-bottom: 0;
    }
    .module-sub {
      margin: 0;
    }
  }
  
}

@media (max-width:767px) {
  .js_leaderModal {
    margin-bottom: 20px;
  }
  .img-wrapper .gutter .img-title-specific {
    width: 70%;
    font-size: 0.9em;
  }
}

.module-sub {
  margin: 0 -15% 40px -15%;
}

//== Components
//## Define common padding and border radius sizes and more. Values based on 14px text and 1.428 line-height (~20px to start).

$padding-small-vertical:   5px !default;
$padding-small-horizontal: 5px !default;

$padding-base-vertical:    10px !default;
$padding-base-horizontal:  10px !default;

$padding-large-vertical:   20px !default;
$padding-large-horizontal: 20px !default;

$margin-small-vertical:    5px !default;
$margin-small-horizontal:  5px !default;

$margin-base-vertical:     10px !default;
$margin-base-horizontal:   10px !default;

$margin-large-vertical:    20px !default;
$margin-large-horizontal:  20px !default;
.pin {
  position: fixed;
  z-index: $zindex-sticky;
  animation: pin 1s ease-in-out;
}

@keyframes pin {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}

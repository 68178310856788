.footer-service {
  padding: 20px 7.5%;
  // height: 470px;
  background-color: #0f2958;
}

.footer-nav {
  float: left;
}

.footer-nav li {
  float: left;
}

.footer-nav li a {
  padding: 0 15px;
  font-size: 16px;
  font-weight: normal;
  text-align: left;
  color: #fff;
}

.footer-nav {
  float: right;
  width: 100%;

  .nav__login {
    font-size: 0.94em;
    margin-top: $_page-header-heigth/2 - 30/2 - 4;
    padding: 0.3em 1.2em;
    height: 30px;
    line-height: 30px;
    background-color: #eb2836;
    color: #fff;
    border-radius: 15px;
  }
}

.foot-partner {
  background-color: #d9d9d9;
  position: relative;
  padding: 2% 0;
}

.foot-partner__title {
  display: inline-block;
  position: absolute;
  top: 30%;
  font-size: 1.875em;
  font-weight: 300;
  color: #000;
}

.foot-partner__list {
  display: inline-block;

  li {
    float: left;
  }
}

.footer-contact {
  margin-top: 50px;
  border-top: 1px solid rgba(255, 255, 255, 0.39);
  border-bottom: 1px solid rgba(255, 255, 255, 0.39);
}

.contact-info {
  margin-top: 50px;
}

.contact-info__special {
  border-left: 1px solid rgba(255, 255, 255, 0.4);

  p {
    padding-left: 1em;
    span {
      padding-left: 0.5em !important;
    }
  }
}

.contact-info__item,
.contact-social__title {
  font-size: $font-size-medium;
  text-align: left;
  color: #fff;
}

.contact-info__item {
  padding: 1em 1em 0.5em 0;
}

.contact-info__content p,
.contact-info__content p a {
  font-size: $font-size-normal;
  font-weight: normal;
  text-align: left;
  color: rgba(255, 255, 255, 0.6);

  .iconfont {
    color: $brand-icon;
  }

  span {
    padding-left: 1.5em;
  }
}

.contact-info__unique {
  padding-left: 0 !important;
}

.contact-social {
  border-top: 1px solid rgba(255, 255, 255, 0.07);
  padding-top: 12px;
}

.contact-social__title {
  float: left;
  margin-right: 10px;
  padding: 0.5em 0;
}

.contact-social__item {
  float: left;
  padding: 0 0.3em;

  a {
    color: rgb(0, 109, 183);
    &:focus,
    &:hover {
      text-decoration: none;
      color: rgb(4, 125, 206);
    }
  }

  .iconfont {
    font-size: 30px;

  }
}

.contact-map {
  margin-top: 1em;
  margin-bottom: 1em;
}

.footer-copyright {
  padding: 30px 0;
  font-size: 0.8125em;
  font-weight: 300;
  text-align: center;
  color: #e0e0e0;
}

@media (max-width: 767px) {
  .foot-partner {
    padding-left: 20%;
  }
  .footer-contact {
    border-top: none;
  }
  .contact-map {
    display: none;
  }
  .contact-info__content {
    margin-bottom: 2em;
  }
  .contact-info__special {
    border-left: none;
  }
  .footer-contact {
    .gutter {
      padding-left: 0;
      padding-right: 0;
      border-top: 1px solid rgba(255, 255, 255, 0.39);
    }
    .contact-info {
      margin-top: 0;
      .contact-info__special {
        p {
          padding-left: 0;
        }
      }
    }
    .module {
      margin-bottom: 0;
    }
  }
  .footer-nav {
    li {
      a {
        font-size: 0.75em;
        padding: 0 0.5em;
      }
    }
    .nav__login {
      font-size: 0.5em;
      display: none;
    }
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .contact-map {
    display: none;
  }
}

.hipaa {
  margin-top: -1.25rem;
}
// Image Mixins

// Responsive image
//
// Keep images from scaling beyond the width of their parents.
// 包裹区域空间再宽，图片不再进行伸展，而是居中显示
@mixin img-responsive($display: block) {
  display: $display;
  width: 100% \9;
  max-width: 100%; // Part 1: Set a maximum relative to the parent
  height: auto; // Part 2: Scale the height according to the width, otherwise you get stretching
  margin-right: auto;
  margin-left: auto;
}

// 图片尺寸填充整个包裹区域
@mixin img-fit($display: block) {
  display: $display;
  width: 100%;
}

//== Colors
//
//## Gray and brand colors for use across Bootstrap.

$gray-base:             #000 !default;
$gray-darker:           #292929 !default;
$gray-dark:             #3f3f3f !default;
$gray:                  lighten($gray-base, 40%) !default;   // #666
$gray-light:            lighten($gray-base, 54.5%) !default; // * #8b8b8b
$gray-lighter:          lighten($gray-base, 96%) !default;   // * #f5f5f5

$brand-icon:            #eb2836 !default; 
$brand-primary:         #eb2837 !default; // *
$brand-accent:          #f7ab27 !default; // *
$brand-info:            #78a1d3 !default; //
$brand-success:         #94cc93 !default; //
$brand-warning:         #ff9435 !default;
$brand-danger:          #d9534f !default;
$brand-error:           #ff0000 !default;

$brand-weak:            #9ebdce !default; //
$brand-muted:           lighten($gray-base, 76.5%) !default; // * #c3c3c3
$brand-disabled:        $brand-muted !default;

//** Background color for `<body>`.
$body-bg:             #fff !default;
$white:               #fff !default;

//** Global text color on `<body>`.

//** Global link style.
$link-color:            $gray-dark !default;
$link-hover-color:      $brand-primary !default;
$link-hover-decoration: underline !default;

//** Horizontal line color & Global border color
$hr-color:              lighten($gray-base, 89.5%) !default; // * #e4e4e4
$border-color:          lighten($gray-base, 89.5%) !default; // * #e4e4e4